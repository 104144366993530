"use strict";

import "./js/inc/_jquery-global.js";
import "./js/inc/_animation.js";
import "./scss/style.scss";
import { data } from "jquery";

window.addEventListener("load", () => {

  /* header 固定・変化
  ----------------------------- */
  const headerHeight = $(".js-header").outerHeight(true);

  function headerChange() {
    const scroll = $(window).scrollTop();
    if (scroll >= headerHeight) {
      $(".js-header").addClass("is-fixed");
    } else {
      $(".js-header").removeClass("is-fixed");
    }
  }

  $(window).on("scroll", function () {
    headerChange();
  });

  /* アンカーリンク
  ----------------------------- */
  $(function () {
    var headH = $(".js-header").outerHeight(); // ヘッダーの高さを取得
    var animeSpeed = 400; // アニメーションスピード
  
    $("a[href^='#']").on("click", function (event) {
      event.preventDefault(); // デフォルトのリンク動作を無効にする
  
      var href = $(this).attr("href");
      var target = $(href == "#" || href === "" ? "html" : href);
  
      if (target.length) {
        var offsetAdjustment = 50; // デフォルトのオフセット調整値
  
        // ウィンドウ幅に応じてオフセット調整値を変更
        if ($(window).width() <= 576) {
          offsetAdjustment = 10;
        } else if ($(window).width() <= 768) {
          offsetAdjustment = 20;
        }
  
        var position = target.offset().top - headH - offsetAdjustment; // ターゲットまでの距離からヘッダーの高さを引く
        $("body,html").stop().animate(
          {
            scrollTop: position
          },
          animeSpeed
        );
      }
  
      return false;
    });
  });

  /* スマホメニュー
  ----------------------------- */
  const hamburger = document.querySelector(".js-hamburger");
  const headerNav = document.querySelector(".js-headerNav");
  const headerNavLinks = document.querySelectorAll(".js-headerNavLink");
  const headerBg = document.querySelector(".js-headerBg");
  const body = document.querySelector("body");

  hamburger.addEventListener("click", () => {
    hamburger.classList.toggle("is-headerMenu-open");
    headerNav.classList.toggle("is-headerMenu-open");
    headerBg.classList.toggle("is-headerMenu-open");
    body.classList.toggle("is-headerMenu-open");
  });

  const closeMenu = () => {
    hamburger.classList.remove("is-headerMenu-open");
    headerNav.classList.remove("is-headerMenu-open");
    headerBg.classList.remove("is-headerMenu-open");
    body.classList.remove("is-headerMenu-open");
  };

  headerNavLinks.forEach((headerNavLink) => {
    headerNavLink.addEventListener("click", () => {
      closeMenu();
    });
  });

  headerBg.addEventListener("click", () => {
    closeMenu();
  });

  /* アコーディオン
  -------------------------------------------- */
  $(".js-accordionHook").on("click", function () {
    $(this).next().slideToggle(400);
    $(this).next().toggleClass("is-accordion-open");
    $(this).toggleClass("is-accordion-open");
  });

  /* メガメニュー
  ----------------------------- */
  const mediaQueryList = window.matchMedia('(min-width: 992px)');
  const listener = (event) => {
    if(event.matches) {
      const headerNavChildHooks = document.querySelectorAll('.js-headerMenuHook');
      headerNavChildHooks.forEach((hook) => {
        const headerNavItemMenu = hook.querySelector('.js-headerNavChild a');
        const headerNavChild = hook.querySelector('.js-headerNavChild');
        const bodyElement = document.querySelector('body');
        if(!headerNavChild.classList.contains('is-headerMegaMenu-open')) {
          hook.addEventListener('mouseover', () => {
            hook.classList.add('is-headerMegaMenu-open');
            headerNavChild.classList.add('is-headerMegaMenu-open');
            bodyElement.classList.add('is-headerMegaMenu-open');
            headerNavItemMenu.classList.add('is-headerMegaMenu-open');
          });
          hook.addEventListener('mouseleave', () => {
            hook.classList.remove('is-headerMegaMenu-open');
            headerNavChild.classList.remove('is-headerMegaMenu-open');
            bodyElement.classList.remove('is-headerMegaMenu-open');
            headerNavItemMenu.classList.remove('is-headerMegaMenu-open');
          });
        }
      });
    }
  }
  mediaQueryList.addEventListener("change", listener);
  listener(mediaQueryList);

  /* 職場紹介スライダー
  ----------------------------- */
  const workplaceEductionElement = document.querySelector('.js-workplaceEduction');
  if (workplaceEductionElement) {
    const workplaceEductionSlider = new Swiper('.js-workplaceEductionSlider', {
      slidesPerView: "auto",
      spaceBetween: 20,
      loop: false,
      speed: 1000,
      grabCursor: true,
      navigation: { 
        nextEl: '.js-workplaceEduction .swiper-button-next',
        prevEl: '.js-workplaceEduction .swiper-button-prev',
      },
      breakpoints: {
        768: {
          spaceBetween: 30,
        },
        992: {
          spaceBetween: 50,
        },
      },
    });
  }

  const workplaceMedical = document.querySelector('.js-workplaceMedical');
  if (workplaceMedical) {
    const workplaceMedicalSlider = new Swiper('.js-workplaceMedicalSlider', {
      slidesPerView: "auto",
      spaceBetween: 20,
      loop: false,
      speed: 1000,
      grabCursor: true,
      navigation: { 
        nextEl: '.js-workplaceMedical .swiper-button-next',
        prevEl: '.js-workplaceMedical .swiper-button-prev',
      },
      breakpoints: {
        768: {
          spaceBetween: 30,
        },
        992: {
          spaceBetween: 50,
        },
      },
    });
  }

  const workplaceCenter = document.querySelector('.js-workplaceCenter');
  if (workplaceCenter) {
    const workplaceCenterSlider = new Swiper('.js-workplaceCenterSlider', {
      slidesPerView: "auto",
      spaceBetween: 20,
      loop: false,
      speed: 1000,
      grabCursor: true,
      navigation: { 
        nextEl: '.js-workplaceCenter .swiper-button-next',
        prevEl: '.js-workplaceCenter .swiper-button-prev',
      },
      breakpoints: {
        768: {
          spaceBetween: 30,
        },
        992: {
          spaceBetween: 50,
        },
      },
    });
  }
  

  /* TOP インタビュースライダー
  ----------------------------- */
  const interviewSlider = document.querySelector('.js-interviewSlider');
  const interviewSliderElement = document.querySelector('.top__interview_slider');
  const interviewSliderControlElement = document.querySelector('.top__interview_sliderControl');

  if (interviewSlider) {
    const slideLength = document.querySelectorAll('.top__interview_slide').length;
    if(slideLength > 1) {
      const interviewSwiper = new Swiper('.js-interviewSlider', {
        slidesPerView: "auto",
        spaceBetween: 20,
        loop: true,
        speed: 1000,
        grabCursor: true,
        navigation: { 
          nextEl: '.top__interview_sliderControl .swiper-button-next',
          prevEl: '.top__interview_sliderControl .swiper-button-prev',
        },
        pagination: {
          el: ".top__interview_sliderControl .swiper-pagination",
          type: "fraction",
          renderFraction: function (currentClass, totalClass) {
            return `<span class="${currentClass}"></span>/<span class="${totalClass}"></span>`;
          },
          formatFractionCurrent: function (number) {
            return number < 10 ? '0' + number : number;
          },
          formatFractionTotal: function (number) {
            return number < 10 ? '0' + number : number;
          },
        },
        breakpoints: {
          768: {
            spaceBetween: 30,
          },
          992: {
            spaceBetween: 50,
          },
        },
      });
    } else {
      interviewSliderElement.classList.add('is-stop');
      interviewSliderControlElement.classList.add('is-stop');
    }
  }

  /* about メッセージスライダー
  ----------------------------- */
  const aboutMessageSlider = document.querySelector('.js-aboutMessageSlider');
  if (aboutMessageSlider) {
    const aboutMessageSlider = new Swiper('.js-aboutMessageSlider', {
      slidesPerView: 1,
      spaceBetween: 20,
      loop: false,
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      speed: 2000,
      grabCursor: true,
      navigation: { 
        nextEl: '',
        prevEl: '',
        // nextEl: '.about__message_slider_button .swiper-button-next',
        // prevEl: '.about__message_slider_button .swiper-button-prev',
      },
    });
  }

  /* データで見る
  ----------------------------- */
  const dataElement = document.querySelector('.js-data');
  if(dataElement) {
    /* カウントアップ */
    const countUpTriggers = document.querySelectorAll('.js-countUpTrigger');
    const countUpTargets = document.querySelectorAll('.js-countUpTarget');

    countUpTriggers.forEach((countUpTrigger, index) => {
      let countUpTarget = countUpTargets[index];
      const elementDic = {
        from: parseFloat(countUpTarget.dataset.from),
        to: parseFloat(countUpTarget.dataset.to)
      };
      // if(isInteger(countUpTarget.dataset.to)) {
      //   console.log("TRUE:"+countUpTarget.dataset.to);
      //   elementDic = {
      //     from: countUpTarget.dataset.from,
      //     to: countUpTarget.dataset.to
      //   };
      // } else {
      //   elementDic = {
      //     from: parseFloat(countUpTarget.dataset.from),
      //     to: parseFloat(countUpTarget.dataset.to)
      //   };
      // }
      
      const elementNum = { count: elementDic.from };
      const duration = countUpTrigger.classList.contains('js-countUpTriggerShort') ? 0.5 : 1;
  
      gsap.to(elementNum, {
        count: elementDic.to,
        duration: duration,
        ease: 'none',
        scrollTrigger: {
          trigger: countUpTrigger,
          start: 'top bottom',
        },
        onUpdate: () => {
          if(countUpTarget.dataset.float === "true") {
            countUpTarget.textContent = elementNum.count.toFixed(1);
          } else {
            countUpTarget.textContent = Math.floor(elementNum.count);
          }
        }
      });
    });

    // ドーナツグラフ
    const dataPieChart = (canvasId, data) => {
      new Chart(canvasId, {
        type: 'pie',
        data: {
          labels: data.labels,
          datasets: [{
            data: data.values,
            borderColor: 'transparent',
            backgroundColor: data.backgroundColor, // 各セクションの背景色を指定
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 800,
            easing: 'easeInOutCirc',
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function(context){
                  let label = context.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.formattedValue !== null) {
                    label += context.formattedValue + '%';
                  }
                  return label;
                }
              }
            }
          }
        }
      });
    };
  
    // 棒グラフ
    const createBarChart = (canvasId, data) => {
      new Chart(canvasId, {
        type: 'bar',
        data: {
          labels: data.labels,
          datasets: data.datasets.map(dataset => ({
            label: dataset.label,
            data: dataset.data,
            backgroundColor: dataset.backgroundColor,
          }))
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 800,
            easing: 'easeInOutCirc',
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function(context){
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.raw !== null) {
                    label += context.raw;
                  }
                  return label;
                }
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 100,
                color: '#000',
                font: {
                  size: 10,
                  weight: '600'
                },
                callback: function(value) {
                  return value;
                }
              },
            },
            x: {
              ticks: {
                color: '#000',
                font: {
                  size: 14,
                  weight: '600'
                }
              },
              grid: {
                display: false // 縦線（グリッドライン）を非表示にする
              }
            }
          }
        }
      });
    };
    
    // データで見る グラフデータ
    const allJobsData = {
      labels: ['法人本部', '大学・専門学校', '病院'],
      backgroundColor: [
        '#F9C3C5',  // 法人本部の背景色
        '#FFB03B',  // 大学・専門学校の背景色
        '#71D2DF'   // 病院の背景色
      ],
      values: [2, 8, 90],
    };

    const allJobsDepartmentData = {
      labels: ['教員', '事務', '技術', '看護', '技能'],
      backgroundColor: [
        '#FFB03B',
        '#67BA8D',
        '#71D2DF',
        '#F9C3C5',
        '#4088C9'
      ],
      datasets: [
        {
          data: [1119, 711, 902, 3109, 84],
          backgroundColor: [
            '#FFB03B',
            '#67BA8D',
            '#71D2DF',
            '#F9C3C5',
            '#4088C9'
          ]
        }
      ]
    };
    
    const officeJobsData = {
      labels: ['法人本部', '大学・専門学校', '病院'],
      backgroundColor: [
        '#F9C3C5',
        '#FFB03B',
        '#71D2DF'
      ],
      values: [14, 17, 63],
    };
    
    const workData = {
      labels: ['医療事務', '庶務事務', '患者支援', '資材業務', '診療録管理', '医師支援', '医療情報', '医療安全', 'その他'],
      backgroundColor: [
        '#71D2DF',
        '#FFB03B',
        '#F9C3C5',
        '#67BA8D',
        '#4088C9',
        '#A17ECE',
        '#C9BBBB',
        '#F58A8E',
        '#4FBFC0',
      ],
      values: [34, 11, 14, 6, 6, 15, 5, 3, 6],
    };
    
    const genderData = {
      labels: ['男性', '女性'],
      backgroundColor: [
        '#71D2DF',
        '#F9C3C5',
      ],
      values: [34, 66],
    };
  
    const positionData = {
      labels: ['管理職', '指導職', '担当職'],
      backgroundColor: [
        '#F9C3C5',
        '#FFB03B',
        '#71D2DF'
      ],
      values: [14, 26, 60],
    };
  
    const positionGenderData = {
      labels: ["管理職", "指導職", "担当職"],
      datasets: [
        {
          label: "男性",
          data: [150, 60, 90],
          backgroundColor: "#71D2DF"
        },
        {
          label: "女性",
          data: [50, 110, 320],
          backgroundColor: "#F58A8E"
        }
      ]
    };
  
    // データで見る グラフ描画
    const charts = [
      { id: "all-jobs", data: allJobsData },
      { id: "all-jobs-department", data: allJobsDepartmentData},
      { id: "office-jobs", data: officeJobsData },
      { id: "work", data: workData },
      { id: "gender", data: genderData },
      { id: "position", data: positionData },
      { id: "position-gender", data: positionGenderData },
    ];
  
    // アニメーションのタイミングを設定
    const chartTiming = function() {
      const scroll = window.scrollY;
      const height = window.innerHeight;
    
      charts.forEach(function(chart) {
        const target = document.getElementById(chart.id).getBoundingClientRect().top + scroll;
    
        if (scroll > target - height && !chart.flag) {
          if (chart.data.datasets) {
            createBarChart(chart.id, chart.data);
          } else {
            dataPieChart(chart.id, chart.data);
          }
          chart.flag = true;
        }
      });
    };
  
    // 初期ロード時にも発火
    window.addEventListener('load', chartTiming);
    window.addEventListener('scroll', chartTiming);

    // 初期ロード時に関数を実行
    chartTiming();
  }

  /* 施設紹介 写真ギャラリー
  ----------------------------- */
  const gallerySlider = document.querySelector('.js-gallerySlider');
  const gallerySliderElement = document.querySelector('.workplace__gallery_slider');

  if(gallerySlider) {
    const slideLength = document.querySelectorAll('.workplace__gallery_slide').length;
    if(slideLength > 1) {
      const gallerySwiper = new Swiper('.js-gallerySlider', {
        slidesPerView: 1.95,
        centeredSlides : true,
        loop: true,
        loopAdditionalSlides: 1,
        grabCursor: true,
        speed: 1000,
        pagination: {
          el: '.workplace__gallery_sliderControl .swiper-pagination',
          type: "progressbar",
        },
        navigation: {
          nextEl: '.workplace__gallery_sliderControl .swiper-button-next',
          prevEl: '.workplace__gallery_sliderControl .swiper-button-prev',
        },
      });
    } else {
      gallerySliderElement.classList.add('is-stop');
    }
  }


  /* 施設紹介 Google Map
  ----------------------------- */
  const embedGmap = document.querySelector('.workplace__info_map');
  if(embedGmap) {
    initMap(embedGmap);
  }
  /* form
  ----------------------------- */
  const contactForm = document.querySelector('.p-form');
  if(contactForm) {

    const formScreen = document.getElementById("formScreen");
    const confirmScreen = document.getElementById("confirmScreen");
    const completeScreen = document.getElementById("completeScreen");
    const formMessage = document.getElementById("formMessage");
    const contactForm = document.getElementById("contactForm");
    const csrfToken = document.getElementById("csrfToken");
    const recaptchaToken = document.getElementById("recaptchaToken");

    console.log(themeDirectory)
    // フォームフィールド情報を取得
    fetch(themeDirectory + "/include/form_config.php") // 'config.php'ファイルにHTTPリクエストを送信
      .then((response) => response.json() ) // レスポンスをJSON形式に変換
      .then((fields) => {
        // JSONデータ（fields）を受け取る
        setupForm(fields); // 取得したデータを元に確認用フォームを設定
        setupValidation(fields); // 取得したデータを元にフォームのバリデーションを設定
      });

    // CSRFトークンを生成して設定
    fetch(themeDirectory + "/include/generate_csrf_token.php") // 'generate_csrf_token.php'ファイルにHTTPリクエストを送信
      .then((response) => response.text()) // レスポンスをテキスト形式に変換
      .then((token) => {
        csrfToken.value = token;
      }); // 取得したトークンをcsrfTokenフィールドに設定


    // 確認ボタンのクリックイベントリスナー
    document
      .getElementById("submitButton")
      .addEventListener("click", function () {
        grecaptcha.ready(function () {
          grecaptcha
            .execute('6LcJYCEqAAAAAAxl33JYbb2oQR9BF1dx9xNbF3bL', {
              action: "submit",
            })
            .then(function (token) {
              recaptchaToken.value = token; // 取得したreCAPTCHAトークンを設定
              const formData = new FormData(contactForm); // フォームデータを生成
              fetch(themeDirectory + "/include/send_mail.php", {
                // メール送信スクリプトにデータを送信
                method: "POST",
                body: formData,
              })
                .then((response) => response.text()) // サーバーからの応答をテキストとして取得
                .then((response) => {
                  // console.log(response);
                  confirmScreen.style.display = "none"; // 確認画面を非表示
                  completeScreen.style.display = "block"; // 完了画面を表示
                  // formMessage.textContent = response; // 応答メッセージを表示
                })
                .catch((error) => {
                  // エラーハンドリング
                  // console.log(error);
                  confirmScreen.style.display = "none"; // 確認画面を非表示
                  formScreen.style.display = "block"; // フォーム画面を再表示
                  formMessage.textContent =
                    "メッセージの送信中にエラーが発生しました。もう一度お試しください。"; // エラーメッセージを表示
                });
            });
        });
      });

    // 戻るボタンのクリックイベントリスナー
    document
      .getElementById("backButton")
      .addEventListener("click", function () {
        confirmScreen.style.display = "none"; // 確認画面を非表示
        formScreen.style.display = "block"; // フォーム画面を表示
      });
  }
});

/* Initiarize Google Map 
  ----------------------------- */
function initMap(el) {
  // Find marker elements within map.
  var markers = el.querySelectorAll('.marker');

  // Create generic map.
  var mapArgs = {
    zoom: Number(el.dataset.zoom) || 16,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
  };
  var map = new google.maps.Map(el, mapArgs);

  // Add markers.
  map.markers = [];
  markers.forEach(function(marker) {
    initMarker(marker, map);
  });

  // Center map based on markers.
  centerMap(map);

  // Return map instance.
  return map;
}

function initMarker(marker, map) {
  // Get position from marker.
  var lat = marker.dataset.lat;
  var lng = marker.dataset.lng;
  var latLng = {
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  };

  // Create marker instance.
  var mapMarker = new google.maps.Marker({
    position: latLng,
    map: map
  });

  // Append to reference for later use.
  map.markers.push(mapMarker);

  // If marker contains HTML, add it to an infoWindow.
  if (marker.innerHTML) {
    // Create info window.
    var infowindow = new google.maps.InfoWindow({
      content: marker.innerHTML
    });

    // Show info window when marker is clicked.
    mapMarker.addListener('click', function() {
      infowindow.open(map, mapMarker);
    });
  }
}

function centerMap(map) {
  // Create map boundaries from all map markers.
  var bounds = new google.maps.LatLngBounds();
  map.markers.forEach(function(marker) {
    bounds.extend({
      lat: marker.position.lat(),
      lng: marker.position.lng()
    });
  });

  // Case: Single marker.
  if (map.markers.length == 1) {
    map.setCenter(bounds.getCenter());
  } else { // Case: Multiple markers.
    map.fitBounds(bounds);
  }
}

/* Form Functions
----------------------------- */

// HTMLエスケープ関数（特殊文字を正しく表示させる）
function escapeHtml(text) {
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#039;",
    "\n": "<br>", // 改行を <br> タグに変換
  };
  return text.replace(/[&<>"'\n]/g, function (m) {
    return map[m];
  });
}

// 改行文字を <br> タグに置き換える
function nl2br(str) {
  return str.replace(/\n/g, "<br>");
}

// 確認画面用フォームの設定関数
function setupForm(fields) {
  const confirmFields = Object.keys(fields).map((field) => {

    const formBlock = document.createElement("div");
    formBlock.className = "p-form__block";

    const formBlockWrap = document.createElement("dl");
    formBlockWrap.className = "p-form__block_wrap";
    formBlock.appendChild(formBlockWrap);

    const formLabel = document.createElement("dt");
    formLabel.className = "p-form__label";
    formBlockWrap.appendChild(formLabel)

    const labelText = document.createElement("p");
    labelText.className = "p-form__label_text";
    labelText.textContent = fields[field].label;
    formLabel.appendChild(labelText);

    const formField = document.createElement("dd");
    formField.className = "p-form__field";
    formField.id = `confirm${capitalizeFirstLetter(field)}`;
    formBlockWrap.appendChild(formField)

    return formBlock;
  });

  confirmFields.forEach((field) =>
    document.querySelector(".form__confirm").appendChild(field)
  );
}

// バリデーション設定関数
function setupValidation(fields) {
  contactForm.addEventListener("submit", function (event) {
    event.preventDefault();
    if (validateForm(fields)) {
      // 確認画面を表示
      formScreen.style.display = "none"; // フォーム画面を非表示
      confirmScreen.style.display = "block"; // 確認画面を表示
      Object.keys(fields).forEach((field) => {
        let value;

        // <TODO>
        // チェックボックス・ラジオボタンのフォーム要素を追加する場合は、
        // 以下の条件分岐にname属性の値とともに追加してください。
        // 必要ない場合は削除してください。
        // if (field === "privacy") {
        //     const checkboxes = document.querySelectorAll('input[name="privacy[]"]:checked');
        //     value = Array.from(checkboxes).map(cb => cb.labels[0].innerText).join(", ");
        // } else if (field === "method") {
        //     const radio = document.querySelector('input[name="method"]:checked');
        //     value = radio ? radio.labels[0].innerText : "";
        // } else {
        value = document.getElementById(field).value; // 各フィールドの値を取得
        // }
        document.getElementById(
          `confirm${capitalizeFirstLetter(field)}`
        ).innerHTML = nl2br(escapeHtml(value)); // 確認画面にエスケープした値を設定
      });
    }
  });
}

// 文字列の先頭を大文字にする関数
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// バリデーション検証関数
function validateForm(fields) {
  let formBlock = "";
  const formBlocks = document.querySelectorAll(".p-form__block");
  let isValid = true; // バリデーションの有効性を示すフラグ

  // すべてのエラーメッセージをクリア
  document.querySelectorAll(".p-form__error_text").forEach((e) => (e.textContent = ""));
  formBlocks.forEach((formBlock) => { formBlock.classList.remove('is-error'); })

  // 各フィールドのバリデーションを実行
  Object.keys(fields).forEach((field) => {
    let isError = false;
    const fieldElement = document.getElementById(field);
    // console.log(formBlocks);
    // チェックボックスの検証
    if (field === "privacy") { // checkboxのfield名を指定してください
      formBlock = fieldElement.parentElement.parentElement.parentElement.parentElement.parentElement;
      console.log(formBlock)
      const checkboxes = document.querySelectorAll(`input[name="privacy[]"]`);
      const isChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
      const errorElem = document.getElementById(`${field}Error`);
      if (fields[field].required && !isChecked) {
        errorElem.textContent = `${fields[field].label}は必須項目です。`;
        isError = true;
      }
    } else if (field === "client") {
      formBlock = fieldElement.parentElement.parentElement.parentElement.parentElement;
    } else {
      formBlock = fieldElement.parentElement.parentElement.parentElement;
    }
    // ラジオボタンの検証
    // if (field === "method") { // radioのfield名を指定してください
    //   const radios = document.querySelectorAll(`input[name=${field}]`);
    //   const isChecked = Array.from(radios).some(radio => radio.checked);
    //   const errorElem = document.getElementById(`${field}Error`);

    //   if (fields[field].required && !isChecked) {
    //     errorElem.textContent = `${fields[field].label}を選択してください。`;
    //     isValid = false;
    //   } else {
    //     errorElem.textContent = "";
    //   }
    //   return; // このフィールドの通常のバリデーションをスキップ
    // }

    // その他のフィールドの検証
    const value = fieldElement.value.trim(); // フィールドの値を取得し、トリミング（前後の空白を削除）
    const errorElem = document.getElementById(`${field}Error`);
    const pattern = fields[field].pattern
        ? new RegExp(fields[field].pattern.slice(1, -1))
        : null; // パターン検証用の正規表現を取得（もし設定されている場合）

    if (fields[field].required && value === "") { // 必須フィールドの検証
        errorElem.textContent = `${fields[field].label}は必須項目です。`;
        isError = true;
    } else if (value !== "" && pattern && !pattern.test(value)) { // パターン検証
        errorElem.textContent = `${fields[field].label}を正しい形式で入力してください。`;
        isError = true;
    // } else if (field === "tel" && value !== "" && (!/^\d{10,11}$/.test(value.replace(/-/g, "")) || value.replace(/-/g, "").length < 10)) {
    //     // 電話番号の桁数検証（ハイフンを取り除いて検証）
    //     errorElem.textContent = `${fields[field].label}は10桁または11桁の数字で入力してください。`;
    //     isValid = false;
    // } else if (field === "email" && value !== "" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    //     // メールアドレスの形式検証
    //     errorElem.textContent = `正しい${fields[field].label}を入力してください。`;
    //     isError = true;
    } else if (field === "email_confirm") {
        // メールアドレスの二重検証
        const emailFieldValue = document.getElementById('email').value.trim();
        if( value !== emailFieldValue) {
          errorElem.textContent = `メールアドレスと一致していません。`;
          isError = true;
        }
    }

    if(isError) {
      isValid = false;
      formBlock.classList.add('is-error');
    }
  });

  return isValid; // フォームの有効性を返す
}


// Floatかどうか判定
function isFloat(n){
  return Number(n) === n && n % 1 !== 0;
}
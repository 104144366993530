/* GSAP アニメーション
-------------------------------------------- */
window.addEventListener("load", function () {

  const tl = gsap.timeline();
  CustomEase.create("custom-animation", "0.25, 0.46, 0.45, 0.94");

  /* フェードアップ */
  const fadeUp = document.querySelectorAll(".js-fadeUp");
  if(fadeUp) {
    gsap.utils.toArray(".js-fadeUp").forEach((target) => {
      gsap.fromTo(
        target,
        {
          autoAlpha: 0,
          y: 15
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.65,
          delay: .1,
          ease: "custom-animation",
          scrollTrigger: {
            trigger: target,
            start: "top 90%"
          }
        }
      );
    });
  }

  /* 連続フェードアップ */
  const fadeUps = document.querySelectorAll(".js-fadeUps");
  if (fadeUps) {
    gsap.set(".js-fadeUps > *", { autoAlpha: 0, y: 35});
    gsap.utils.toArray(".js-fadeUps").forEach((element) => {
      let targets = element.querySelectorAll(":scope > *");
      gsap.fromTo(
        targets,
        { autoAlpha: 0, y: 35 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.8,
          delay: .1,
          ease: "custom-animation",
          stagger: 0.2,
          scrollTrigger: {
            trigger: element,
            start: "top 85%"
          }
        }
      );
    });
  }

  /* フェードイン */
  const fadeIn = document.querySelectorAll(".js-fadeIn");
  if(fadeIn) {
    gsap.utils.toArray(".js-fadeIn").forEach((target) => {
      gsap.fromTo(
        target,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.75,
          delay: .25,
          ease: "custom-animation",
          scrollTrigger: {
            trigger: target,
            start: "top 90%"
          }
        }
      );
    });
  }
});

/* GSAP スクロールトリガー ページの初期ロード時にも反映
-------------------------------------------- */
window.addEventListener("load", function () {
  ScrollTrigger.refresh();
});